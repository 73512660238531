import React, { FC, useMemo } from "react";
import "react-phone-number-input/style.css";
import { useTranslation } from "react-i18next";
import { Control, Controller } from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import { getContainer } from "../../../diContainer/container";
import { ILocalizationService } from "../../../boundary/ILocalizationService";
import { MuiTelInput, MuiTelInputCountry } from "mui-tel-input";
import { TextFieldProps } from "@mui/material/TextField/TextField";
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LANGUAGE_CODE,
} from "../../../adapters/services/localizationService";

type FormData = {
  phone: string;
};

type IProps = {
  control: Control<FormData, any>;
  label?: string;
} & TextFieldProps;

const MIN_PHONE_LENGTH = 9;

export const PhoneInput: FC<IProps> = ({ control, label, ...props }) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "common.phoneInput",
  });
  const { t: tError } = useTranslation("translation", {
    keyPrefix: "common.errors.phone",
  });

  const localizationService = getContainer().getDependency(
    "localizationService"
  ) as ILocalizationService;

  const localizationData = useObservable(localizationService.data$);

  const [isoCountryCode, isoLangCode]: [MuiTelInputCountry, string] = useMemo(
    () => [
      (localizationData?.country || DEFAULT_COUNTRY_CODE) as MuiTelInputCountry,
      localizationData?.language || DEFAULT_LANGUAGE_CODE,
    ],
    [localizationData]
  );

  return (
    <Controller
      name="phone"
      control={control}
      rules={{
        required: true,
        minLength: MIN_PHONE_LENGTH,
      }}
      render={({ field: { onChange, value }, fieldState }) => {
        let errorText = null;

        if (fieldState.error?.message) {
          errorText = tError(fieldState.error?.message) || tError("required");
        }

        const helperText = fieldState.error?.message ? errorText : null;
        return (
          <MuiTelInput
            {...props}
            value={value}
            onChange={onChange}
            preferredCountries={[isoCountryCode]}
            defaultCountry={isoCountryCode}
            langOfCountryName={isoLangCode}
            helperText={helperText}
            error={fieldState.invalid} // labels={ua}
            label={label ?? t("placeholder")}
            data-testid="phone-input"
          />
        );
      }}
    />
  );
};
