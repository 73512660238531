export default function PlaneLeftImg({}) {
  return (
    <svg
      width="376"
      height="150"
      viewBox="0 0 376 150"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.00003 139.851L142.257 148.778L139.367 108.377L164.783 102.817L136.663 70.0854L133.619 26.3551C130.194 27.6009 44.4459 102.538 2.00003 139.851Z"
        fill="#1976D2"
      />
      <path
        d="M142.257 148.778L2.00003 139.851M142.257 148.778L103.634 99.7387M142.257 148.778L139.367 108.377M2.00003 139.851L103.634 99.7387M2.00003 139.851C44.4459 102.538 130.194 27.6009 133.619 26.3551M2.00003 139.851L104.073 88.1335M103.634 99.7387L109.083 97.9224M137.932 88.3063L139.367 108.377M137.932 88.3063L164.783 102.817M137.932 88.3063L109.083 97.9224M139.367 108.377L164.783 102.817M164.783 102.817L136.663 70.0854M133.619 26.3551L136.663 70.0854M133.619 26.3551L104.073 88.1335M136.663 70.0854L104.073 88.1335M104.073 88.1335L109.083 97.9224"
        stroke="white"
      />
      <path
        d="M170 95.5C256.2 72.3378 301.232 51.9694 375 1"
        stroke="#1976D2"
        strokeWidth="2"
        strokeDasharray="8 8"
      />
    </svg>
  );
}
