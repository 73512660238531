import { StateSubject } from "@roketus/web-toolkit";
import { MetaAttributes } from "@grafana/faro-react";
import { ISDK } from "@roketus/loyalty-end-user-js-sdk";
import {
  IProfileService,
  IProfileServiceData,
} from "../../boundary/IProfileService";
import { toPromoCodesEntity } from "../data/promoCodes/toPromoCodesEntities";
import { toPassAndBarcodeEntities } from "../data/toPassAndBarcodeEntities";
import {
  EC_FETCH_PROFILE_INFO,
} from "../../domain/specs/errorCodes";
import { fetchPassDataByCardNumber } from "../api/fetchPassDataByCardNumber";
import {
  pushErrorToFaro,
  setUserAttributesMetaToFaro,
  setUserMetaToFaro,
} from "../../utils/faroLogs";
import { createDispatchError } from "../helpers";
import { isNoCardError } from "../../utils/response-errors";
import { getAuthService, getRouterService } from "../../diContainer/getDependencies";

const dispatchError = createDispatchError("profileService");

const stateMachine = new StateSubject<IProfileServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
  profile: null,
  promoCodes: [],
  barcodeInfo: null,
  passInfo: null,
});

export const init = function (sdk: ISDK): IProfileService {
  const clearState = () => {
    stateMachine.setState({
      loading: false,
      isLoaded: false,
      failed: false,
      profile: null,
      promoCodes: [],
      barcodeInfo: null,
      passInfo: null,
    });
  };

  const getProfileInfo = async () => {
    try {
      const { data: profile } = await sdk.profile.getProfileInfo().result;

      stateMachine.setState({
        profile,
      });

      setUserMetaToFaro(
        "id",
        profile.profile[0]?.id as string & MetaAttributes
      );

      return profile;
    } catch (e) {
      pushErrorToFaro(e, {
        message: "Error: failed to fetch profile info",
      });

      throw e;
    }
  };

  const getFullProfileData = async () => {
    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    try {
      const { data: card } = await sdk.card.getCardInfo().result;

      // Add card number to faro logs
      setUserAttributesMetaToFaro(
        "cardNumber",
        card?.CardNumber as string & MetaAttributes
      );

      const { data: bonusesData } = await sdk.balance.getBonuses().result;
      const passData = await fetchPassDataByCardNumber(card?.CardNumber);

      const [passInfo, barcodeInfo] = toPassAndBarcodeEntities(
        passData,
        card?.CardNumber
      );

      const promoCodes = toPromoCodesEntity(card?.Promocodes || []);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        fullName: card?.NameOnCard,
        discount: card?.BonusPercent,
        cardNumber: card?.CardNumber,
        bonusBalance:
          bonusesData?.totalActive.UAH || bonusesData?.totalActive.USD || bonusesData?.totalActive.EUR || 0,
        barcodeInfo,
        passInfo,
        promoCodes,
      });
    } catch (e) {
      stateMachine.setState({
        loading: false,
        isLoaded: false,
      });

      pushErrorToFaro(e, {
        message: "Error: failed to fetch full profile info",
      });

      const message = "Failed to fetch full profile info";
      dispatchError({
        code: EC_FETCH_PROFILE_INFO,
        error: e,
        message,
      });

      if (isNoCardError(e)) {
        const authService = getAuthService();
        const routerService = getRouterService();

        authService.removeTokens();
        routerService.navigateToIssuerPath('/signup');
      }
    }
  };

  return {
    data$: stateMachine.state$,
    clearState,
    getProfileInfo,
    getFullProfileData,
  };
};
