import { isNull, isUndefined } from "lodash";
import { getContainer } from "../diContainer/container";
import { createErrorMessage, ErrorMessageBus } from "@roketus/web-toolkit";
import { IssuerParams } from "../domain/specs/IssuerParams";
import {
  DEFAULT_DARK_BG_COLOR,
  DEFAULT_DARK_PRIMARY_COLOR,
  DEFAULT_LIGHT_BG_COLOR,
  DEFAULT_LIGHT_PRIMARY_COLOR,
} from "../domain/specs/theme";
import { isAndroidOS, isSamsungBrowser } from "../utils/utils";

export const createDispatchError =
  (source: string) =>
  ({
    code,
    error,
    message,
  }: {
    code: string;
    error: unknown;
    message: string;
  }) => {
    const errorMessageBus = getContainer().getDependency(
      "errorMessageBus"
    ) as ErrorMessageBus;

    const errorMessage = createErrorMessage({
      code,
      source,
      message,
      error: error as Error,
    });
    errorMessageBus.send(errorMessage);
  };

export const getPrimaryColorForApp = (
  config: Partial<Record<IssuerParams, string>>,
  isDarkTheme: boolean
): string => {
  const { darkThemePrimaryColor, lightThemePrimaryColor } = config;

  if (isDarkTheme && !isUndefined(darkThemePrimaryColor)) {
    return darkThemePrimaryColor;
  }
  if (!isDarkTheme && !isUndefined(lightThemePrimaryColor)) {
    return lightThemePrimaryColor;
  }

  return isDarkTheme ? DEFAULT_DARK_PRIMARY_COLOR : DEFAULT_LIGHT_PRIMARY_COLOR;
};

export const getBgColorForApp = (
  config: Partial<Record<IssuerParams, string>>,
  isDarkTheme: boolean
): string => {
  const { darkThemeBodyBgColor, lightThemeBodyBgColor } = config;

  if (isDarkTheme && !isUndefined(darkThemeBodyBgColor))
    return darkThemeBodyBgColor;
  if (!isDarkTheme && !isUndefined(lightThemeBodyBgColor))
    return lightThemeBodyBgColor;

  return isDarkTheme ? DEFAULT_DARK_BG_COLOR : DEFAULT_LIGHT_BG_COLOR;
};

export const setMetaThemeColor = (color: string) => {
  const metaThemeColor = document.querySelector("meta[name=theme-color]");
  if (!isNull(metaThemeColor)) {
    metaThemeColor.setAttribute("content", color);
  }
};

export const downloadIOSCardAsBlob = (data: Blob) => {
  const url = window.URL.createObjectURL(data);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", "apple.pkpass");
  link.click();
  link.parentNode?.removeChild(link);
};

export const isValidPhoneNumber = (phoneNumber?: string): boolean => {
  if (!phoneNumber) return false;

  const phoneNumberWithoutCountryCode = phoneNumber
    .split(" ")
    .splice(1)
    .join("");

  return phoneNumberWithoutCountryCode.length >= 4;
};

const getOS = (): string => {
  if (/Windows/.test(navigator.userAgent)) {
    return "Windows";
  } else if (/Macintosh/.test(navigator.userAgent)) {
    return "MacOS";
  } else if (isAndroidOS()) {
    return "Android";
  } else if (/iPhone|iPad|iPod/.test(navigator.userAgent)) {
    return "IOS";
  } else if (/Linux/.test(navigator.userAgent)) {
    return "Linux";
  } else {
    return "other";
  }
};

const getBrowser = (): string => {
  if (
    /Chrome/.test(navigator.userAgent) &&
    /Google Inc/.test(navigator.vendor)
  ) {
    return "Chrome";
  } else if (isSamsungBrowser()) {
    return "Samsung";
  } else if (/MiuiBrowser/.test(navigator.userAgent)) {
    return "Mi";
  } else if (/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) {
    return "Safari";
  } else {
    return "other";
  }
};

export const getUniqueAnonymousInfo = () => {
  const operatingSystem = getOS();
  const browser = getBrowser();
  const language = navigator.language;
  const languages = navigator.languages;
  const screenWidth = window.screen.width;
  const screenHeight = window.screen.height;
  const colorDepth = window.screen.colorDepth;
  const currentDate = new Date();
  const currentTimezoneOffset = currentDate.getTimezoneOffset();

  return `${operatingSystem},${browser},${language},${languages.toString()},${screenWidth}x${screenHeight},${colorDepth},${currentDate.toISOString()},${currentTimezoneOffset}`;
};
