import { StateSubject } from "@roketus/web-toolkit";
import { ISDK } from "@roketus/loyalty-end-user-js-sdk";
import {
  IRouterService,
  IRouterServiceData,
} from "../../boundary/IRouterService";
import { getContainer } from "../../diContainer/container";
import { IRouter } from "../../boundary/IRouter";
import { IIssuerConfigService } from "../../boundary/IIssuerConfigService";
import { IProfileService } from "../../boundary/IProfileService";
import { IActionService } from "../../boundary/IActionService";
import { ActionsPaths } from "../router/actionsRoutes";
import { getNextRegStepPath } from "../../utils/registration";

const stateMachine = new StateSubject<IRouterServiceData>({});

export const init = (sdk: ISDK): IRouterService => {
  const navigateToIssuerPath: IRouterService["navigateToIssuerPath"] = (
    path,
    state
  ) => {
    const router = getContainer().getDependency("router") as IRouter;
    const issuerConfigService = getContainer().getDependency(
      "issuerConfigService"
    ) as IIssuerConfigService;
  
    const issuerSysName = issuerConfigService.getIssuer(sdk);
  
    router.navigate(`/${issuerSysName}${path}`, { state });
  };
  
  const navigateToInitialPage: IRouterService["navigateToInitialPage"] = () => {
    const actionService = getContainer().getDependency(
      "actionService"
    ) as IActionService;
  
    const { dispatchKey, actionType } = actionService.data$.value;
  
    let path = "/";
  
    if (dispatchKey) {
      path = actionType === ActionsPaths.Survey ? "/survey" : "/action";
    }
  
    navigateToIssuerPath(path);
  };
  
  const navigateToNextRegStep: IRouterService["navigateToNextRegStep"] = (
    stepInfo
  ) => {
    if (stepInfo.completed) {
      navigateToInitialPage();
      return;
    }
  
    const stepPath = getNextRegStepPath(stepInfo.nextStep);
  
    navigateToIssuerPath(stepPath);
  };
  
  const getReferralLink: IRouterService["getReferralLink"] = () => {
    const profileService = getContainer().getDependency(
      "profileService"
    ) as IProfileService;
    const issuerConfigService = getContainer().getDependency(
      "issuerConfigService"
    ) as IIssuerConfigService;
  
    const refCode = profileService.data$.value.profile?.refCode;
    const issuerSysName = issuerConfigService.getIssuer(sdk);
  
    if (issuerSysName && refCode) {
      return `${window.location.origin}/${issuerSysName}/signup?refCode=${refCode}`;
    }
  
    return null;
  };

  return {
    data$: stateMachine.state$,
    navigateToIssuerPath,
    navigateToNextRegStep,
    navigateToInitialPage,
    getReferralLink,
  };
};
