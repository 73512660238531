import { get, isEmpty, isString } from "lodash";
import { StateSubject } from "@roketus/web-toolkit";
import type {
  IActionService,
  IActionServiceData,
} from "../../boundary/IActionService";
import type { ISurveyFormPayload } from "../../boundary/forms/IFreeSurveyFormData";
import {
  getAuthService,
  getGoogleAnalyticsService,
  getIssuerConfigService,
  getRouterService,
  getSdk,
  getSignupService,
} from "../../diContainer/getDependencies";
import { SURVEY_ANALYTICS } from "../../domain/specs/analyticsParams";
import { ActionsPaths } from "../router/actionsRoutes";
import { getErrorCodeFromResponse } from "../../utils/response-errors";
import { pushErrorToFaro, pushLogToFaro } from "../../utils/faroLogs";
import { downloadIOSCardAsBlob, getUniqueAnonymousInfo } from "../helpers";
import { toPostSurveyPayloadDTO } from "../data/survey/toPostSurveyPayloadDTO";

// Response types from server for dispatch action endroint (depending on action type)
export const ACTIONS_WITH_DOWNLOAD_RESPONSE_TYPE = [
  ActionsPaths.Coupon, // ios - blob, other - google link in json
];
export const ACTIONS_WITH_EMPTY_RESPONSE_TYPE = [
  ActionsPaths.Promo,
  ActionsPaths.VodohraiPromo,
]; // 204 - nobody
export const ACTIONS_WITH_JSON_RESPONSE_TYPE = [
  ActionsPaths.Bonus,
  ActionsPaths.Notification,
]; // json

const stateMachine = new StateSubject<IActionServiceData>({
  dispatchKey: "",
  actionType: "",
  loading: false,
  resultData: null,
});

const storeAction = (actionType: string, dispatchKey: string) => {
  stateMachine.setState({
    actionType,
    dispatchKey,
  });
};

const clearAction = () => {
  stateMachine.setState({
    actionType: "",
    dispatchKey: "",
  });
};

const approveAction = async (phoneNumber: string) => {
  stateMachine.setState({
    loading: true,
  });

  const routerService = getRouterService();
  const signUpService = getSignupService();
  const authService = getAuthService();

  const { actionType } = stateMachine.state$.value;
  const { isAuthenticated } = authService.data$.value;

  if (isAuthenticated) {
    stateMachine.setState({
      loading: false,
    });

    actionType === ActionsPaths.Survey
      ? routerService.navigateToIssuerPath("/survey")
      : routerService.navigateToIssuerPath("/action", { actionType });

    return;
  }

  // TODO: remove after API returns template id;
  // HARDCODE for OnDe vodohrai promo
  const params =
    actionType === ActionsPaths.VodohraiPromo ? { templateId: 1651 } : {};

  await signUpService.setPhone(phoneNumber, params);

  stateMachine.setState({
    loading: false,
  });
};

const approvePublicAction = async () => {
  await dispatchPublicAction();
};

const dispatchAction = async () => {
  stateMachine.setState({
    loading: true,
  });

  const sdk = getSdk();
  const routerService = getRouterService();

  const { dispatchKey, actionType } = stateMachine.state$.value;

  try {
    const data = await sdk.action.dispatchAction(dispatchKey).result;

    if (
      ![
        ActionsPaths.Bonus,
        ActionsPaths.Notification,
        ...ACTIONS_WITH_EMPTY_RESPONSE_TYPE,
      ].includes(actionType as ActionsPaths)
    ) {
      const googlePassUrl = get(data, "data[0]attributes.google_pass_url");
      googlePassUrl && window.open(googlePassUrl, "_blank");
    }

    pushLogToFaro("Success: dispatch action", { actionType });

    routerService.navigateToIssuerPath("/action-success", { actionType });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  } catch (e) {
    pushErrorToFaro(e, {
      actionType,
      message: "Error: failed to dispatch action",
    });

    const errorCode = getErrorCodeFromResponse(e);

    routerService.navigateToIssuerPath("/action-info", {
      actionType,
      errorCode,
    });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  }
};

const dispatchPublicAction = async () => {
  stateMachine.setState({
    loading: true,
  });

  const routerService = getRouterService();
  const issuerConfigService = getIssuerConfigService();

  const { actionType, dispatchKey } = stateMachine.state$.value;
  const issuerSysName = issuerConfigService.getIssuer();

  try {
    const baseURL = process.env.REACT_APP_LOYALTY_API_URL;
    const url = `${baseURL}smart-link/dispatch-public/${dispatchKey}`;
    const params = `?errorRedirectUrl=${window.location.origin}/${issuerSysName}/action-info`;
    const linkTarget = "_blank";

    pushLogToFaro("Dispatch public action", {
      actionType,
    });

    // problems with CORS from google
    // const data = await sdk.action.dispatchPublicAction(dispatchKey).result;

    // [BE] if success - redirects to download page, else - to error page
    window.open(`${url}${params}`, linkTarget);

    routerService.navigateToIssuerPath("/action-success", { actionType });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  } catch (e) {
    pushErrorToFaro(e, {
      actionType,
      message: "Error: failed to dispatch public action",
    });

    routerService.navigateToIssuerPath("/action-info", { actionType });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  }
};

const submitSurveyForm = async (surveyFormPayload: ISurveyFormPayload) => {
  stateMachine.setState({
    loading: true,
  });

  const sdk = getSdk();
  const routerService = getRouterService();

  const issuerSysName = sdk.getIssuer() as string;
  const jwt = sdk.getToken();

  if (!isString(jwt) || isEmpty(jwt)) {
    routerService.navigateToIssuerPath("/signin");
    return;
  }

  //TODO: if is not authorized - no access to the page and method
  try {
    const payload = toPostSurveyPayloadDTO(
      issuerSysName,
      {
        authorized: true,
        jwt,
      },
      surveyFormPayload
    );

    await sdk.survey.postSurvey(payload).result;

    routerService.navigateToIssuerPath("/action");

    stateMachine.setState({
      loading: false,
    });
  } catch (error) {
    routerService.navigateToIssuerPath("/info");

    stateMachine.setState({
      loading: false,
    });
  }
};

const submitFreeSurveyForm = async (
  surveyPayload: ISurveyFormPayload,
  issuerSysName?: string
) => {
  if (!issuerSysName) {
    throw new Error("Not Found");
  }

  stateMachine.setState({
    loading: true,
  });

  const sdk = getSdk();
  const routerService = getRouterService();
  const googleAnalyticsService = getGoogleAnalyticsService();

  const questionAnswer = surveyPayload.surveyData[0].questionAnswers[0].value;

  googleAnalyticsService.sendGoogleAnalyticsEvent(
    SURVEY_ANALYTICS.eventName,
    SURVEY_ANALYTICS.eventParameter,
    questionAnswer ? "True" : "False"
  );

  try {
    const payload = toPostSurveyPayloadDTO(
      issuerSysName,
      {
        authorized: false,
        jwt: getUniqueAnonymousInfo(),
      },
      surveyPayload
    );

    await sdk.survey.postSurvey(payload).result;

    if (questionAnswer) {
      routerService.navigateToIssuerPath("/success");
    } else {
      routerService.navigateToIssuerPath("/info");
    }

    stateMachine.setState({
      loading: false,
    });
  } catch (error) {
    if (questionAnswer) {
      routerService.navigateToIssuerPath("/success");
    } else {
      routerService.navigateToIssuerPath("/info");
    }

    stateMachine.setState({
      loading: false,
    });
  }
};

const dispatchIOSCardAction = async () => {
  stateMachine.setState({
    loading: true,
  });

  const sdk = getSdk();
  const routerService = getRouterService();

  const { actionType, dispatchKey } = stateMachine.state$.value;

  try {
    const IOSCard = (await sdk.action.dispatchIOSCardAction(dispatchKey)
      .result) as unknown as Blob;

    downloadIOSCardAsBlob(IOSCard);

    pushLogToFaro("Success: dispatch action on iOS", {
      actionType,
    });

    routerService.navigateToIssuerPath("/action-success", { actionType });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  } catch (e) {
    pushErrorToFaro(e, {
      message: "Error: failed to dispatch action on iOS",
      actionType,
    });

    routerService.navigateToIssuerPath("/action-info", { actionType });

    clearAction();

    stateMachine.setState({
      loading: false,
    });
  }
};

export const actionService: IActionService = {
  data$: stateMachine.state$,
  storeAction,
  clearAction,
  dispatchAction,
  dispatchIOSCardAction,
  dispatchPublicAction,
  submitSurveyForm,
  submitFreeSurveyForm,
  approveAction,
  approvePublicAction,
};
