import {
  EC_FAILED_FETCH_CONFIG_DATA,
  EC_INVALID_ISSUER_SYS_NAME,
  EC_NO_CARD,
} from "../../domain/specs/errorCodes";
import { Ii18nScheme } from "./en";

const uk: Ii18nScheme = {
  translation: {
    forms: {
      default: {
        required: "* Обов'язкове поле",
      },
    },
    common: {
      copy: "Скопіювати",
      copiedMessage: "Посилання скопійовано!",
      phoneInput: {
        placeholder: "Введіть номер телефону",
      },
      errors: {
        "user not found": "Ой помилка, користувача не знайдено",
        "wrong phone code": "Ой помилка, невірний код",
        isRequired: "{{label}} є обов'язковим",
        unknownTitle: "Помилка",
        unknownText: "Упс, щось пішло не так!",
        firstNameError: "Невірний формат",
        lastNameError: "Невірний формат",
        minLength: "Значення занадто коротке",
        phone: {
          required: "Невірний формат",
          "phone belong another user": "Номер вже зареєстровано",
          unknownText: "Упс, щось пішло не так, спробуйте ще",
          "user not found": "Ой помилка, користувача не знайдено",
          "incorrect phone": "Не коректний номер телефону",
        },
        otp: {
          required: `Обов'язкове поле`,
          pattern: "Невірний формат",
          minLength: "Мінімальна довжина 6 символів",
          maxLength: "Максимальна довжина 6 символів",
          "wrong phone code": "Невірний код",
          unknownText: "Упс, щось пішло не так, спробуйте ще",
        },
      },
      date: {
        month: {
          January: "Січень",
          February: "Лютий",
          March: "Березень",
          April: "Квітень",
          May: "Травень",
          June: "Червень",
          July: "Липень",
          August: "Серпень",
          September: "Вересень",
          October: "Жовтень",
          November: "Листопад",
          December: "Грудень",
        },
      },
      showAll: "Показати усі",
      hide: "Приховати",
    },
    signIn: {
      index: {
        header: "Увійти",
        text: "Номер телефону",
        buttonLabel: "Приєднатись, якщо ще не з нами",
        confirmationLabel: "Код підтвердження",
        company: "Компанія",
      },
      validateOtp: {
        header: "Будь ласка, введіть код підтвердження",
        text: "6 цифр, що надійшли на ваш номер телефону xxx xxxx {{phoneNumberSlice}}.",
        changePhoneNumber: "Назад",
        noCode: "Не приходить код?",
        resendCode: "Надіслати код ще раз",
        waitResend: "Зачекайте 30 секунд та спробуйте ще",
        buttonLabel: "Підтвердити",
      },
    },
    signUp: {
      setPhone: {
        header: "Реєстрація",
        text: "Ми надішлемо вам код підтвердження",
        buttonLabel: "Відправити код {{countdownTitle}}",
        termsAndConditionsLabel:
          "Я погоджуюсь з <lt>Правилами Програми лояльності</lt> та <lc>Положенням про обробку і захист персональних даних</lc>",
        hasAccount: "Вже маєте акаунт?",
        company: "Компанія",
      },
      phoneActivation: {
        title: "Вам вдалося!",
        header:
          "Вітаємо в програмі лояльності {{issuerTitle}} з усіма її перевагами.<br />Насолоджуйтесь широким вибором бонусів, заощаджень, знижок та інших пропозицій.",
        bonuses: "НЕЗАБАРОМ ВИ ОТРИМАЄТЕ ВАШІ БОНУСИ!",
        moreBonuses: "Заробити більше бонусів",
        bonusesProgram: "Бонусна Програма",
        complete: "Закінчіть активацію, щоб отримати більше бонусів.",
        buttonLabel: "Продовжити",
      },
      setProfile: {
        firstNameError: "Невірний формат",
        firstNameLabel: "Ім’я",
        lastNameError: "Невірний формат",
        lastNameLabel: "Прізвище",
        continueButton: "Підтвердити",
        skipButton: "",
        header: "Надайте інформацію про себе",
        cardPreview: "Попередній вигляд карти",
      },
      setBirthday: {
        header: "Встановити дату народження",
        continueButton: "Підтвердити",
        dayLabel: "Число",
        monthLabel: "Місяць",
        yearLabel: "Рік",
      },
      step: {
        Basic: "",
        Profile: "Введіть ім’я та прізвище",
        SetPhone: "",
        PhoneActivation: "",
        PhoneActivationSuccess: "",
        SetEmail: "Підтвердити електронну пошту",
        EmailActivation: "",
        SetBirthday: "Встановити дату народження",
        Completed: "Профіль",
      },
    },
    profile: {
      title: "Профіль",
      company: "Компанія: {{name}}",
      organizationName: "Назва організації:",
      description: "Опис:",
      mainText:
        "Ваша карта лояльності {{issuerTitle}} завжди з вами. Використовуйте її для отримання переваг та знижок в {{issuerTitle}}.",
      dateLabel: "Дійсний до: ",
      downloadCardForIos: "Скачати картку для iOS",
      downloadCardForAndroid: "Скачати картку для Android",
      balanceLabel: "Баланс",
      discountLabel: "Бонус за покупку",
      cardNumberLabel: "Номер карти",
      downloadCardLabel: "Додайте карту в гаманець",
      logout: "Вийти",
      logoutConfirm: "Ви впевнені, що бажаєте вийти?",
      yes: "Так",
      no: "Ні",
      promoCodes: "Промокоди",
    },
    changeProfile: {
      title: "Змінити ім’я та прізвище",
      buttonLabel: "Змінити",
      firstNameLabel: "Ім’я",
      lastNameLabel: "Прізвище",
    },
    changePhoneNumber: {
      title: "Змінити номер телефону",
      buttonLabel: "Змінити",
      updateButtonLabel: "Змінити {{time}}",
    },
    changeEmail: {
      title: "Змінити електронну пошту",
      buttonLabel: "Змінити",
    },
    emailVerification: {
      send: "Надіслати підтвердження",
      skip: "Пропустити",
      title: "Підтвердьте адресу електронної пошти!",
      message: "Введіть свою електронну адресу.",
      email: "Eлектронна пошта",
    },
    referralProgram:{
      title: "Запросіть свого друга",
      description: "Скопіюйте посилання та надішліть його другу",
    },
    action: {
      thanksForScanning: "Дякуємо за сканування QR-коду!",
      thanksForChoosing:
        "Ми щиро вдячні вам за те, що ви вирішили скористатися нашою спеціальною пропозицією!",
      valuableCustomer:
        "Ви наш найцінніший клієнт, і ваш вибір робить нас ще кращими",
      reward: "Винагорода",
      bonus: {
        reward:
          "У вас є шанс отримати 100 бонусів, які ви можете витратити в нашому магазині",
        leavePhone:
          "Будь ласка, залиште свій номер телефону та отримайте бонуси",
      },
      coupon: {
        reward:
          "У вас є шанс отримати купон, який ви можете використати у нашому магазині",
        leavePhone: "Будь ласка, залиште свій номер телефону та отримайте його",
      },
      promo: {
        reward:
          "У вас є шанс отримати промо код, який ви можете використати у нашому магазині",
        leavePhone: "Будь ласка, залиште свій номер телефону та отримайте його",
      },
      notification: {
        reward:
          "Ви маєте шанс отримати унікальну пропозицію від нашого магазину",
        leavePhone: "Будь ласка, залиште свій номер телефону та отримайте її",
      },
      survey: {
        reward:
          "Ви маєте шанс отримати 100 бонусів, які ви можете витратити в нашому магазині після проходження опитування",
        leavePhone:
          "Будь ласка, залиште свій номер телефону, щоб отримати бонуси",
      },
      surveyForm: {
        question: "Вам подобається у нас?",
        yes: "Так",
        no: "Ні",
        next: "Далі",
      },
      dispatchResult: {
        successHeader: "Вітаємо!",
        failHeader: "Щось пішло не так",
        sorryHeader: "Вибачте",
        warning: "Увага!",
        successBonusText:
          "Вам буде нараховано бонусні бали<br />*бали будуть активовані відповідно до правил ПЛ",
        successCouponText: "Ви отримали купон",
        successPromoText: "Ви отримаєте промо код",
        successNotificationText: "Ви отримаєте спеціальну пропозицію",
        failBonusText: "Бонуси не нараховані",
        failCouponText: "Не вдалося відправити купон",
        failNotificationText: "Неможливо відправити повідомлення",
        failPromoText: "Не вдалося відправити промо код",
        unknownErrorText: "Пропозиція вже активована",
        promoCodeNotFound: "Такого промо кода не знайдено",
        alreadyUsedBonuses:
          "Ви вже скористалися даною пропозицією",
        alreadyAddedPromoCode: "Промокод вже додано",
        alreadyUsedPromoCode: "Промокод на подарунок вже було використано",
        promoCodeExpired: "Акційна пропозиція більше недоступна",
        promoCodeNotAvailable: "Акційна пропозиція недоступна",
        toMainPage: "На головну",
        close: "Закрити",
        pleaseWait: "Будь-ласка, зачекайте",
      },
      demoatomic: {
        bonusTitle: "<span>Дякуємо</span> за сканування QR коду!",
        surveyTitle:
          "Будь ласка, дайте відповідь на <span>одне питання</span>. Вам сподобалося в нашому магазині?",
        qrTitle: "<span>Тільки сьогодні!</span> Ексклюзивна пропозиція",
        couponTitle:
          "<span>Тільки сьогодні!</span> Приєднуйся та отримуй ексклюзивні знижки й бонуси вже зараз!",
        successTitle: "<span>Дякуємо</span> за Ваш час та взаємодію",
        errorTitle: "<span>Ой!</span> Щось пішло не так",
        sorryTitle: "<span>Вибачте!</span><br/>Побачимось згодом!",
        bonusText:
          "Ми щиро вдячні вам за те, що ви вирішили скористатися нашою <b>спеціальною пропозицією</b>! Ви наш найцінніший клієнт, і ваш вибір робить нас ще кращими. Ви маєте можливість скористатися перевагами нашої <b>програми лояльності</b>",
        phoneLabel:
          "Будь ласка, залиште свій номер телефону, щоб скористатися цією можливістю",
        surveyQuestion: "Вам сподобалося в нашому магазині?",
        surveyYes: "Мені дуже сподобалось",
        surveyNo: "Могло б бути краще",
        homeButton: "На головну",
        close: "Відміна",
        confirm: "Далі",
      },
    },
    landing: {
      title: "Інформація про товар",
      text: "Наші товари найкращі серед усіх товарів",
    },
    welcomePage: {
      ok: "Далі",
      cancel: "Відміна",
    },
    infoPage: {
      message: "Вибачте.<br />До зустрічі!",
      notFound: "Вибачте.<br />Сторінка не знайдена",
    },
    footer: {
      help: "Допомога",
    },
    errorPage: {
      [EC_FAILED_FETCH_CONFIG_DATA]: "Failed to fetch config",
      [EC_INVALID_ISSUER_SYS_NAME]:
        "Щось не так із посиланням, будь ласка, скануйте ще раз",
      [EC_NO_CARD]: "Не знайдено картку Loyalty",
      unknownTitle: "Oops!",
      unknownText:
        "Програма лояльності, до якої ви намагаєтесь отримати доступ, ще не запущена, або URL-адреса неправильна. Слідкуйте за оновленнями!",
      actionText: "На головну",
    },
  },
};

export default uk;
