import React, { FC } from "react";
import { noop } from "lodash";
import { useObservable } from "@roketus/web-toolkit";
import { getContainer } from "../../diContainer/container";
import { ISignupService } from "../../boundary/ISignUpService";
import LoadingButton from "@mui/lab/LoadingButton";
import { ButtonProps } from "@mui/material/Button/Button";

export const SignupButtonWithLoading: FC<Omit<ButtonProps, "isLoading">> = ({
  onClick,
  ...rest
}) => {
  const signupService = getContainer().getDependency(
    "signupService"
  ) as ISignupService;
  const data = useObservable(signupService.data$);
  const handleClick = data?.loading ? noop : onClick;

  return (
    <LoadingButton
      type="submit"
      fullWidth
      variant="contained"
      sx={{ mt: 3, mb: 2 }}
      {...rest}
      onClick={handleClick}
      loading={data?.loading}
    />
  );
};
