import { useEffect, FC } from "react";
import { isEmpty } from "lodash";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Grid, Typography, Link } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  getAuthService,
  getIssuerConfigService,
  getMessageBus,
  getSigninService,
} from "../../diContainer/getDependencies";
import { buildAnalyticMessageEntity } from "../../domain/entities/analyticEntity";
import { isValidPhoneNumber } from "../../adapters/helpers";
import { LayoutHeadless } from "../components/layouts/LayoutHeadless";
import { PhoneInput } from "../components/form/PhoneInput";

export const SignInPage: FC = () => {
  const { t } = useTranslation("translation", { keyPrefix: "signIn.index" });

  const { handleSubmit, control, setError, watch } = useForm<{
    phone: string;
  }>();

  const navigate = useNavigate();
  const { issuerSysName } = useParams();

  const issuerConfigService = getIssuerConfigService();
  const signInService = getSigninService();
  const authService = getAuthService();
  const messageBus = getMessageBus();

  const onSubmit = async (data: { phone: string }) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SignInButtonClick",
      source: "SignInPage",
    });
    messageBus.send(analyticEvent);

    await signInService.logIn(data.phone);
  };

  const issuerConfigData = useObservable(issuerConfigService.data$);
  const authData = useObservable(signInService.data$);

  useEffect(() => {
    if (!isEmpty(authData?.errorMessage)) {
      setError("phone", { message: authData?.errorMessage });
    }
  }, [setError, authData?.errorMessage]);

  const phoneNumberValue = watch("phone");
  const isDisabled = !isValidPhoneNumber(phoneNumberValue);

  const goToSignUp = (e: React.MouseEvent) => {
    authService.removeTokens();
    navigate(`/${issuerSysName}/signup`);
  };

  return (
    <LayoutHeadless>
      <Typography component="h1" variant="h4">
        {t("header")}
      </Typography>
      {issuerConfigData?.config?.companyName && (
        <Typography variant="subtitle1" align="center" my={2}>
          {t("company")}: {issuerConfigData.config.companyName}
        </Typography>
      )}
      <Typography variant="body1" align="center">
        {t("text")}
      </Typography>
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 3, display: "block", width: "100%" }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PhoneInput control={control} fullWidth />
          </Grid>
          <Grid item xs={12}>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              fullWidth
              loading={authData?.loading}
              data-testid="submit"
              disabled={isDisabled}
            >
              {t("confirmationLabel")}
            </LoadingButton>
          </Grid>
          <Grid item xs={12} container justifyContent="flex-end">
            <Link component="button" onClick={goToSignUp}>
              {t("buttonLabel")}
            </Link>
          </Grid>
        </Grid>
      </Box>
    </LayoutHeadless>
  );
};
