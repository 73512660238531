import {
  EC_FAILED_FETCH_CONFIG_DATA,
  EC_INVALID_ISSUER_SYS_NAME,
  EC_NO_CARD,
} from "../../domain/specs/errorCodes";

const en = {
  translation: {
    forms: {
      default: {
        required: "* Required field",
      },
    },
    common: {
      copy: "Copy",
      copiedMessage: "Link copied!",
      phoneInput: {
        placeholder: "Enter phone number",
      },
      errors: {
        "user not found": "User not found",
        "wrong phone code": "Wrong phone code",
        isRequired: "{{label}} is required",
        unknownTitle: "Error",
        unknownText: "Oops, something went wrong",
        firstNameError: "First Name is not valid",
        lastNameError: "Last Name is not valid",
        minLength: "Input value is too short",
        phone: {
          required: "Invalid phone number",
          "phone belong another user": "Phone number already registered",
          unknownText: "Oops, something went wrong, please try again later",
          "user not found": "User not found",
          "incorrect phone": "Incorrect phone number",
        },
        otp: {
          required: `otp is required`,
          pattern: "only numbers are allowed",
          minLength: "Min length 6 symbols",
          maxLength: "Max length 6 symbols",
          "wrong phone code": "Wrong phone code",
          unknownText: "Oops, something went wrong, please try again later",
        },
      },
      date: {
        month: {
          January: "January",
          February: "February",
          March: "March",
          April: "April",
          May: "May",
          June: "June",
          July: "July",
          August: "August",
          September: "September",
          October: "October",
          November: "November",
          December: "December",
        },
      },
      showAll: "Show all",
      hide: "Hide",
    },
    signIn: {
      index: {
        header: "Sign In",
        text: "Choose Your Country Code and enter your phone number",
        buttonLabel: "Sign Up",
        confirmationLabel: "Send Confirmation Code",
        company: "Company",
      },
      validateOtp: {
        header: "Please enter your verification code",
        text: "Enter the 6-digit code we have sent to xxx xxxx {{phoneNumberSlice}}.",
        changePhoneNumber: "Edit phone number",
        noCode: "Did not receive verification code?",
        resendCode: "Resend code",
        waitResend: "Wait 30 seconds to try again",
        buttonLabel: "Verify code",
      },
    },
    signUp: {
      setPhone: {
        header: "Sign Up",
        text: "We will send you a 6-digit verification code",
        buttonLabel: "Send Code {{countdownTitle}}",
        termsAndConditionsLabel:
          "I agree to the <lt>TERMS AND CONDITIONS</lt> AND <lc>PRIVACY POLICY</lc>",
        hasAccount: "Already have an account?",
        company: "Company",
      },
      phoneActivation: {
        title: "You’re in!",
        header:
          "Welcome to {{issuerTitle}} loyalty program and all its benefits.<br />Enjoy a wide range of earnings, offers and more.",
        bonuses: "YOU WILL RECEIVE YOUR BONUSES SHORTLY!",
        moreBonuses: "Earn more bonuses",
        bonusesProgram: "Bonuses Program",
        complete: "Complete your activation to add more bonuses.",
        buttonLabel: "Continue",
      },
      setProfile: {
        firstNameError: "First Name is not valid",
        firstNameLabel: "First Name",
        lastNameError: "Last Name is not valid",
        lastNameLabel: "Last Name",
        continueButton: "Continue",
        skipButton: "Skip",
        header: "Please enter your name",
        cardPreview: "Card Preview",
      },
      setBirthday: {
        header: "Please enter your birthday",
        continueButton: "Continue",
        dayLabel: "Day of the month",
        monthLabel: "Month",
        yearLabel: "Year",
      },
      step: {
        Basic: "",
        Profile: "Set Profile",
        SetPhone: "",
        PhoneActivation: "",
        PhoneActivationSuccess: "",
        SetEmail: "Verify Your Email",
        EmailActivation: "",
        SetBirthday: "Set Birthday",
        Completed: "Profile",
      },
    },
    profile: {
      title: "Your Account",
      company: "Company: {{name}}",
      organizationName: "Organization Name:",
      description: "Description:",
      mainText:
        "This is your {{issuerTitle}} loyalty program account where you can check your balance, invite friends and manage your personal information.",
      dateLabel: "Valid through: ",
      downloadCardForIos: "Download card for iOS",
      downloadCardForAndroid: "Download card for Android",
      balanceLabel: "Balance",
      discountLabel: "Purchase Bonus",
      cardNumberLabel: "Card Number",
      downloadCardLabel: "Download your Digital Card",
      logout: "Log Out",
      logoutConfirm: "Are you sure you want to exit?",
      yes: "Yes",
      no: "No",
      promoCodes: "Promo Codes",
    },
    changeProfile: {
      title: "Update Personal Info",
      buttonLabel: "Update",
      firstNameLabel: "First Name",
      lastNameLabel: "Last Name",
    },
    changePhoneNumber: {
      title: "Update your mobile phone number",
      buttonLabel: "Update",
      updateButtonLabel: "Update {{time}}",
    },
    changeEmail: {
      title: "Update your email",
      buttonLabel: "Update",
    },
    emailVerification: {
      send: "Send Confirmation",
      skip: "Skip",
      title: "Verify Email Address!",
      message: "Please enter your valid email address.",
      email: "Email",
    },
    referralProgram: {
      title: "Invite your friend",
      description:
        "Copy the link and send it to a friend",
    },
    action: {
      thanksForScanning: "Thanks for scanning the QR code!",
      thanksForChoosing:
        "We sincerely thank you for choosing to take advantage of our special offer!",
      valuableCustomer:
        "You are our most valuable customers, and your choice makes us even better",
      reward: "Reward",
      bonus: {
        reward:
          "You have a chance to get 100 bonuses that you can spend in our store",
        leavePhone: "Please leave your phone number to receive bonuses",
      },
      coupon: {
        reward:
          "You have a chance to get a coupon that you can use in our store",
        leavePhone: "Please leave your phone number and receive it",
      },
      promo: {
        reward:
          "You have a chance to get a promo code that you can use in our store",
        leavePhone: "Please leave your phone number and receive it",
      },
      notification: {
        reward: "You have a chance to get a unique offer from our store",
        leavePhone: "Please leave your phone number and receive it",
      },
      survey: {
        reward:
          "You have a chance to receive 100 bonuses that you can spend in our store after completing the survey",
        leavePhone: "Please leave your phone number to receive bonuses",
      },
      surveyForm: {
        question: "Do you like our store?",
        yes: "Yes",
        no: "No",
        next: "Next",
      },
      dispatchResult: {
        successHeader: "Congratulations!",
        failHeader: "Something went wrong",
        sorryHeader: "Sorry",
        warning: "WARNING!",
        successBonusText:
          "You will be awarded bonus points<br />*bonus points will be activated according to LP rules",
        successCouponText: "You have received an offer",
        successPromoText: "You will receive a promo code",
        successNotificationText: "You will receive an offer",
        failBonusText: "Bonuses have not been awarded",
        failCouponText: "Failed to send coupon",
        failNotificationText: "Failed to send message",
        failPromoText: "Failed to send promo code",
        unknownErrorText: "The offer is already activated",
        promoCodeNotFound: "The Promo code could not be found",
        alreadyUsedBonuses: "Bonuses have already been used",
        alreadyAddedPromoCode: "Promo code has already been added",
        alreadyUsedPromoCode: "The gift promo code has already been used",
        promoCodeExpired: "Promotional offer is no longer available",
        promoCodeNotAvailable: "Promotional offer is not available",
        toMainPage: "Go to main page",
        close: "Close",
        pleaseWait: "Please, wait",
      },
      demoatomic: {
        bonusTitle: "<span>Thank</span> you for scanning the QR code!",
        surveyTitle:
          "Please answer <span>one question</span>. Did you enjoy being in our store?",
        qrTitle: "<span>Only today!</span> Exclusive offer",
        couponTitle:
          "<span>Only today!</span> Join now and get exclusive discounts and bonuses!",
        successTitle: "<span>Thanks</span> for your time and cooperation",
        errorTitle: "<span>Oops!</span> Something went wrong",
        sorryTitle: "<span>Sorry!</span><br/>See you soon!",
        bonusText:
          "We sincerely thank you for choosing to take advantage of <b>our special offer</b>! You are our most valuable customer and your choice makes us even better. You have a chance to get the benefits of <b>our loyalty program</b>",
        surveyQuestion: "Did you enjoy being in our store?",
        surveyYes: "I really liked it",
        surveyNo: "Could be better",
        phoneLabel: "Please leave your phone number to use this opportunity",
        homeButton: "Home",
        close: "Cancel",
        confirm: "Confirm",
      },
    },
    landing: {
      title: "Product information",
      text: "Our products are the best among all products",
    },
    welcomePage: {
      ok: "OK",
      cancel: "Cancel",
    },
    infoPage: {
      message: "Sorry.<br />See you soon!",
      notFound: "Sorry.<br />Page not found",
    },
    footer: {
      help: "Help",
    },
    errorPage: {
      [EC_FAILED_FETCH_CONFIG_DATA]: "Failed to fetch config",
      [EC_INVALID_ISSUER_SYS_NAME]:
        "Something wrong with the link, please scan again",
      [EC_NO_CARD]: "Loyalty card not found",
      unknownTitle: "Oops!",
      unknownText:
        "The Loyalty Program you are trying to access has not started yet or the URL is incorrect. Coming soon, stay tuned!",
      actionText: "Go to Home Page",
    },
  },
};

export type Ii18nScheme = typeof en;

export default en;
