export default function PlaneRightImg({}) {
  return (
    <svg
      width="580"
      height="389"
      viewBox="0 0 580 389"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M577.951 1.26245L478.753 103.079L453.523 70.8013L430.68 83.8653L429.584 40.233L402.212 5.35246C405.627 3.96457 520.794 2.0475 577.951 1.26245Z"
        fill="#1976D2"
      />
      <path
        d="M478.753 103.079L577.951 1.26245M478.753 103.079L474.48 40.086M478.753 103.079L453.523 70.8013M577.951 1.26245L474.48 40.086M577.951 1.26245C520.794 2.0475 405.627 3.96457 402.212 5.35246M577.951 1.26245L466.279 31.6754M474.48 40.086L469.159 42.4184M440.989 54.7665L453.523 70.8013M440.989 54.7665L430.68 83.8653M440.989 54.7665L469.159 42.4184M453.523 70.8013L430.68 83.8653M430.68 83.8653L429.584 40.233M402.212 5.35246L429.584 40.233M402.212 5.35246L466.279 31.6754M429.584 40.233L466.279 31.6754M466.279 31.6754L469.159 42.4184"
        stroke="white"
      />
      <path
        d="M533.782 243.06C386.073 306.819 -124.128 152.699 30.4307 341.372C151.504 489.169 346.117 244.683 454.877 87.4819"
        stroke="#1976D2"
        strokeWidth="2"
        strokeDasharray="8 8"
      />
    </svg>
  );
}
