import { isEmpty, isString } from "lodash";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { IIssuerConfigService } from "../../../boundary/IIssuerConfigService";
import { IAuthService } from "../../../boundary/IAuthService";

export const createUSDemoConfigLoader =
  (issuerConfigService: IIssuerConfigService, authService: IAuthService) =>
  async (props: LoaderFunctionArgs) => {
    const { url } = props.request;

    const querySearchParams = new URL(url).searchParams;

    const issuer = querySearchParams.get("issuer");
    const jwt = querySearchParams.get("jwt");

    if (!isString(issuer) || isEmpty(issuer) || !isString(jwt) || isEmpty(jwt))
      throw new Error("Invalid token");

    try {
      await issuerConfigService.fetchConfig(issuer);

      issuerConfigService.setIssuer(issuer);
      authService.setTokens(jwt);
      authService.setIsAuthenticated();

      return redirect(`/${issuer}/demo`);
    } catch (e) {
      throw new Error("Invalid token");
    }
  };
