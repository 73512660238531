import { FC, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Box, Button, Grid } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useForm } from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";

import { getContainer } from "../../../diContainer/container";
import { ISignupService } from "../../../boundary/ISignUpService";
import {
  ActionsPaths,
  AnonymousActionsPaths,
} from "../../../adapters/router/actionsRoutes";
import { isValidPhoneNumber } from "../../../adapters/helpers";
import { useIsAuthenticated } from "../../../adapters/hooks/useIsAuthenticated";
import { LayoutHeadless } from "../layouts/LayoutHeadless";
import { LoyaltyPolicy } from "../../components/LoyaltyPolicy";
import { PhoneInput } from "../form/PhoneInput";
import { BonusActionDescription } from "../../components/actionComponents/BonusActionDescription";
import { CouponActionDescription } from "../../components/actionComponents/CouponActionDescription";
import { NotificationActionDescription } from "../../components/actionComponents/NotificationActionDescription";
import { SurveyActionDescription } from "../../components/actionComponents/SurveyActionDescription";
import { PromoActionDescription } from "../../components/actionComponents/PromoActionDescription";

type TPhoneFormData = {
  phone: string;
};

interface IProps {
  isAnonymous: boolean;
  onSubmit: (formData: TPhoneFormData) => void;
  onCancel: () => void;
}

export const StandardWelcomePage: FC<IProps> = ({
  isAnonymous,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "welcomePage",
  });
  const signUpService = getContainer().getDependency(
    "signupService"
  ) as ISignupService;

  const location = useLocation();
  const actionType = location.pathname.split("/").at(-1);

  const [termsChecked, setTermsChecked] = useState(false);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    watch,
  } = useForm<TPhoneFormData>();

  const signUpData = useObservable(signUpService.data$);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isEmpty(signUpData?.errorMessage)) {
      setError("phone", { message: signUpData?.errorMessage });
    }
  }, [setError, signUpData?.errorMessage]);

  const phoneNumberValue = watch("phone");
  const isDisabled =
    !isAnonymous &&
    (!isValidPhoneNumber(phoneNumberValue) ||
      !termsChecked ||
      !isEmpty(errors)) &&
    !isAuthenticated;

  return (
    <LayoutHeadless>
      {actionType === ActionsPaths.Bonus && <BonusActionDescription />}
      {(actionType === ActionsPaths.Coupon ||
        actionType === AnonymousActionsPaths.AnonymousCoupon) && (
        <CouponActionDescription isAnonymous={isAnonymous} />
      )}
      {(actionType === ActionsPaths.Promo ||
        actionType === ActionsPaths.VodohraiPromo) && (
        <PromoActionDescription />
      )}
      {actionType === ActionsPaths.Notification && (
        <NotificationActionDescription />
      )}
      {actionType === ActionsPaths.Survey && <SurveyActionDescription />}
      <Box
        component="form"
        noValidate
        onSubmit={handleSubmit(onSubmit)}
        sx={{ mt: 3, display: "block", width: "100%" }}
      >
        <Grid container spacing={2}>
          {!isAnonymous && !isAuthenticated && (
            <>
              <Grid item xs={12}>
                <PhoneInput control={control} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <LoyaltyPolicy
                  termsChecked={termsChecked}
                  setTermsChecked={setTermsChecked}
                />
              </Grid>
            </>
          )}
          <Grid
            item
            xs={12}
            sx={{ display: "flex", gap: "20px", justifyContent: "flex-end" }}
          >
            <Button variant="outlined" onClick={onCancel}>
              {t("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              onClick={handleSubmit(onSubmit)}
              type="submit"
              disabled={isDisabled}
            >
              {t("ok")}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </LayoutHeadless>
  );
};
