import { useObservable } from "@roketus/web-toolkit";
import { getContainer } from "../../diContainer/container";
import { IAuthService } from "../../boundary/IAuthService";

export const useIsAuthenticated = (): boolean => {
  const authService = getContainer().getDependency(
    "authService"
  ) as IAuthService;
  const authData = useObservable(authService.data$);

  //TODO: extract isAuthorized into a separate hook.
  return !!authData?.isAuthenticated && !!authData?.isAuthorized;
};
