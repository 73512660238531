import { Box, FormControl, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { LayoutHeadless } from "./layouts/LayoutHeadless";
import { IActionService } from "../../boundary/IActionService";
import { getContainer } from "../../diContainer/container";
import { ControlRadioGroup } from "./form/ControlRadioGroup";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { useObservable } from "@roketus/web-toolkit";
import { LoadingButton } from "@mui/lab";
import { ISurveyFormPayload } from "../../boundary/forms/IFreeSurveyFormData";

interface SurveyFormProps {
  submitForm: (data: ISurveyFormPayload) => void;
}

interface ISurveyFormData {
  survey: string;
}

export const SurveyForm: React.FC<SurveyFormProps> = ({ submitForm }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ISurveyFormData>({
    defaultValues: { survey: "" },
  });

  const { t } = useTranslation("translation", {
    keyPrefix: "action",
  });

  const actionService = getContainer().getDependency(
    "actionService"
  ) as IActionService;

  const actionData = useObservable(actionService.data$);

  const questionDescription = t("surveyForm.question");

  const onSubmit = ({ survey }: ISurveyFormData) => {
    submitForm({
      surveyDescription: "Authorized user survey",
      surveyData: [
        {
          questionDescription,
          questionAnswers: [
            {
              name: "yes",
              value: survey === "true",
            },
            {
              name: "no",
              value: survey !== "true",
            },
          ],
        },
      ],
    });
  };

  const radioItems = [
    {
      label: t("surveyForm.yes"),
      value: "true",
    },
    {
      label: t("surveyForm.no"),
      value: "false",
    },
  ];

  return (
    <LayoutHeadless>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Typography variant="body1" align="center" gutterBottom>
          {questionDescription}
        </Typography>
        <FormControl sx={{ height: "65px" }}>
          <ControlRadioGroup<ISurveyFormData>
            control={control}
            radioItems={radioItems}
            name="survey"
            required
            wrapperProps={{
              row: true,
            }}
          />
        </FormControl>
        <LoadingButton
          type="submit"
          variant="contained"
          sx={{ mt: "20px" }}
          disabled={!isEmpty(errors) || actionData?.loading}
          loading={actionData?.loading}
        >
          {t("surveyForm.next")}
        </LoadingButton>
      </Box>
    </LayoutHeadless>
  );
};
