import { StateSubject } from "@roketus/web-toolkit";
import {
  ISignupService,
  ISignupServiceData,
} from "../../boundary/ISignUpService";
import { ISignInService } from "../../boundary/ISignInService";
import { getContainer } from "../../diContainer/container";
import { ISDK } from "@roketus/loyalty-end-user-js-sdk";
import { get, isUndefined } from "lodash";
import {
  CustomCodeError,
  EC_SIGNUP_RESEND_CODE,
  EC_SIGNUP_SET_CODE,
  EC_SIGNUP_SET_PHONE,
  EC_SIGNUP_SET_PROFILE,
} from "../../domain/specs/errorCodes";
import { IActionService } from "../../boundary/IActionService";
import { IAuthService } from "../../boundary/IAuthService";
import { IRouterService } from "../../boundary/IRouterService";
import { pushErrorToFaro, pushLogToFaro } from "../../utils/faroLogs";
import {
  isAxiosError,
  isUserAlreadyRegisteredError,
} from "../../utils/response-errors";
import { getIssuerConfigService } from "../../diContainer/getDependencies";

const stateMachine = new StateSubject<ISignupServiceData>({
  loading: false,
  isLoaded: false,
  failed: false,
});

const ACTIVATION_MODE = "50a7f0c9";

export const signupService: ISignupService = {
  setPhoneNumber: (phoneNumber) => {
    stateMachine.setState({
      phoneNumber,
    });
  },

  setPhone: async (phoneNumber, params) => {
    pushLogToFaro("Sign up: set phone");

    const sdk = getContainer().getDependency("sdk") as ISDK;
    const routerService = getContainer().getDependency(
      "routerService"
    ) as IRouterService;
    const authService = getContainer().getDependency(
      "authService"
    ) as IAuthService;

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
      errorMessage: "",
    });

    try {
      const { data } = await sdk.signup.setPhone(
        ACTIVATION_MODE,
        phoneNumber.replace(/\s+/g, ""),
        params
      ).result;

      const token = data.user?.token;
      const refreshToken = data.user?.refresh;

      if (!isUndefined(token)) {
        authService.setTokens(token, refreshToken);
      }

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        phoneNumber,
      });

      pushLogToFaro("Success: set phone on sign up");

      routerService.navigateToNextRegStep(data);

      return null;
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setPhone",
        message: "Error: failed to set phone on sign up",
      });

      if (isAxiosError(e)) {
        const errorText = get(e, "response.data.data[0].title", "unknownText");

        if (isUserAlreadyRegisteredError(e)) {
          const signInService = getContainer().getDependency(
            "signInService"
          ) as ISignInService;

          signInService.setPhoneNumberOTP(phoneNumber);

          stateMachine.setState({
            loading: false,
            isLoaded: true,
            failed: false,
          });

          pushLogToFaro("Sign up: redirecting to signin flow", {
            issuer: String(getIssuerConfigService().getIssuer()),
          });

          routerService.navigateToIssuerPath("/signin/validate-otp");

          return null;
        }

        stateMachine.setState({
          errorMessage: errorText,
        });
      }

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      return new CustomCodeError(EC_SIGNUP_SET_PHONE);
    }
  },

  setCode: async (code, setError) => {
    pushLogToFaro("Sign up: set OTP");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getContainer().getDependency("sdk") as ISDK;
    const routerService = getContainer().getDependency(
      "routerService"
    ) as IRouterService;

    try {
      await sdk.signup.phoneActivation(ACTIVATION_MODE, parseInt(code, 10))
        .result;
      pushLogToFaro("Success: phone activation on signup");

      const { data } = await sdk.signup.regWithTerms(ACTIVATION_MODE, true)
        .result;
      pushLogToFaro("Success: registration with terms");

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
      });

      return null;
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setCode",
        message: "Error: failed to set OTP on signup",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      if (e instanceof Error) {
        const errorText = get(e, "response.data.data[0].title", "unknownText");

        setError("otp", { message: errorText, type: errorText });
        return e;
      }

      return new CustomCodeError(EC_SIGNUP_SET_CODE);
    }
  },

  resendCode: async () => {
    pushLogToFaro("Sign up: resend OTP on signup");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getContainer().getDependency("sdk") as ISDK;

    try {
      await sdk.phone.resendActivationCode().result;

      pushLogToFaro("Success: resend OTP on signup");

      stateMachine.setState({
        loading: false,
        isLoaded: true,
      });

      return null;
    } catch (e) {
      pushErrorToFaro(e, {
        actiom: "resendCode",
        message: "Error: failed to resend OTP on signup",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      if (e instanceof Error) {
        return e;
      }

      return new CustomCodeError(EC_SIGNUP_RESEND_CODE);
    }
  },

  setProfile: async (firstName: string, lastName: string) => {
    pushLogToFaro("Sign up: set profile");

    stateMachine.setState({
      loading: true,
      isLoaded: false,
      failed: false,
    });

    const sdk = getContainer().getDependency("sdk") as ISDK;
    const routerService = getContainer().getDependency(
      "routerService"
    ) as IRouterService;
    const action = getContainer().getDependency(
      "actionService"
    ) as IActionService;
    const authService = getContainer().getDependency(
      "authService"
    ) as IAuthService;

    try {
      const { data } = await sdk.signup.setProfile(ACTIVATION_MODE, {
        firstName,
        lastName,
      }).result;

      stateMachine.setState({
        loading: false,
        isLoaded: true,
      });

      authService.setIsAuthenticated();

      const { actionType } = action.data$.value;
      pushLogToFaro("Success: set profile", { actionType });

      routerService.navigateToNextRegStep(data);

      stateMachine.setState({
        loading: false,
        isLoaded: true,
      });

      return null;
    } catch (e) {
      pushErrorToFaro(e, {
        action: "setProfile",
        message: "Error: failed to set profile",
      });

      stateMachine.setState({
        loading: false,
        isLoaded: true,
        failed: true,
      });

      if (e instanceof Error) {
        return e;
      }

      return new CustomCodeError(EC_SIGNUP_SET_PROFILE);
    }
  },

  data$: stateMachine.state$,
};
