import React, { FC, useMemo } from "react";
import { ThemeProvider as MUIThemeProvider } from "@mui/material";
import { getContainer } from "../diContainer/container";
import { IAppStylingService } from "../boundary/IAppStylingService";
import { useObservable } from "@roketus/web-toolkit";

export const ThemeProvider: FC<{
  children?: React.ReactNode;
}> = ({ children }) => {
  const appStylingService = getContainer().getDependency(
    "appStylingService"
  ) as IAppStylingService;
  const data = useObservable(appStylingService.state);
  const theme = useMemo(() => {
    return data ?? appStylingService.createThemeByConfig({});
  }, [data, appStylingService]);

  return <MUIThemeProvider theme={theme}>{children}</MUIThemeProvider>;
};
