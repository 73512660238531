import React, { FC } from "react";
import { useObservable } from "@roketus/web-toolkit";
import { ValidateOtp as CValidateOtpPage } from "../components/ValidateOtp";
import { UseFormSetError } from "react-hook-form";
import { IOTPFormData } from "../../boundary/IOTPFormData";
import { buildAnalyticMessageEntity } from "../../domain/entities/analyticEntity";
import { getMessageBus, getSignupService } from "../../diContainer/getDependencies";

export const SignUpOTPPage: FC = () => {
  const signupService = getSignupService();
  const messageBus = getMessageBus();

  const data = useObservable(signupService.data$);
  const phoneNumber = data?.phoneNumber ?? "";
  const sendCode = (otp: string, setError: UseFormSetError<IOTPFormData>) => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "SendOtpButtonClick",
      source: "SignUpOTPPage",
    });
    messageBus.send(analyticEvent);

    signupService.setCode(otp, setError);
  };

  const handleResend = () => {
    const analyticEvent = buildAnalyticMessageEntity({
      action: "ResendOtpButtonClick",
      source: "SignUpOTPPage",
    });
    messageBus.send(analyticEvent);

    signupService.resendCode();
  };

  return (
    <CValidateOtpPage
      onSendOtpClick={sendCode}
      phoneNumber={phoneNumber}
      onResendActivationCode={handleResend}
      isLoading={data?.loading ?? false}
    />
  );
};
