import { isEmpty, isString } from "lodash";

export const isInvalidIssuerSysName = (
  issuerSysName?: string | null
): boolean => {
  return (
    !issuerSysName ||
    !isString(issuerSysName) ||
    isEmpty(issuerSysName) ||
    issuerSysName === "null" ||
    issuerSysName === "undefined"
  );
};

export const isRegistrationCompleted = (response: any): boolean => {
  return response?.activation?.[0]?.completed;
};

export const getNextRegStep = (response: any): string => {
  return response?.activation?.[0]?.nextStep || "";
};

export const getNextRegStepPath = (
  nextStep: string = "",
  isRedirectedFromError?: boolean
): string => {
  switch (nextStep) {
    case "completed":
      return "/";
    case "profile":
      return "/signup/profile";
    case "phoneActivation":
      return isRedirectedFromError ? "/signup" : "/signup/validate-otp";
    default:
      return "/signup";
  }
};
