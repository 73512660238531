import { LoaderFunctionArgs } from "react-router-dom";
import { ACTION_DISPATCH_SEARCH_PARAM } from "../actionsRoutes";
import { IActionService } from "../../../boundary/IActionService";

export const createActionLoader =
  (actionService: IActionService) => async (props: LoaderFunctionArgs) => {
    const { url: requestUrl } = props.request;

    const url = new URL(requestUrl);
    const actionType = url.pathname.split("/").at(-1);
    const querySearchParams = url.searchParams;
    const dispatchKey = querySearchParams.get(ACTION_DISPATCH_SEARCH_PARAM);

    actionService.storeAction(actionType || "", dispatchKey || "");

    return null;
  };
