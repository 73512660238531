import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en';
import uk from './uk';
import lt from './lt';

// use ISO standards for locales
// https://github.com/ladjs/i18n-locales

// Browser language detector
// https://github.com/i18next/i18next-browser-languageDetector

export const I18N_STORAGE_KEY = 'i18nextLng';

export const LANGUAGE_OPTIONS = [
  {label: 'English', shortLabel: 'EN', value: 'en' },
  {label: 'Lietuvių', shortLabel: 'LT', value: 'lt' },
  {label: 'Українська', shortLabel: 'UA', value: 'uk' },
];

const resources = {
  en,
  uk,
  lt,
};

export const initI18n = () =>
  i18n
    .use(LanguageDetector) // detect and set browser language
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      supportedLngs: ['en', 'uk', 'lt'],
      debug: true,
      fallbackLng: 'en',
      defaultNS: 'translation',
      detection: {
        order: ['localStorage', 'cookie'],
        caches: ['localStorage', 'cookie'], // Save the detected language in localStorage or cookies
      },
      interpolation: {
        escapeValue: false, // react already safes from xss
      },
      react: {
        transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'u'],
      },
    });

export { i18n };
