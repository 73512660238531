import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Link, Snackbar, TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import LinkIcon from "@mui/icons-material/Link";
import CopyIcon from "@mui/icons-material/ContentCopy";
import { copyText } from "../../../utils/utils";
import { pushErrorToFaro, pushEventToFaro } from "../../../utils/faroLogs";
import { getMessageBus } from "../../../diContainer/getDependencies";
import { buildAnalyticMessageEntity } from "../../../domain/entities/analyticEntity";

interface Props {
  url?: string | null;
}

const CopyLink: FC<Props> = ({ url = "" }) => {
  const { t } = useTranslation("translation", { keyPrefix: "common" });

  const messageBus = getMessageBus();

  const [copied, setCopied] = useState(false);

  const handleCopyClick = () => {
    if (url) {
      try {
        copyText(url, () => setCopied(true));

        pushEventToFaro("Referral link copied");

        const analyticEvent = buildAnalyticMessageEntity({
          action: "CopyReferralLinkClick",
          source: "ReferralBox",
        });
        messageBus.send(analyticEvent);
      } catch (e) {
        pushErrorToFaro(e, { message: "Error: failed to copy ref link" });
      }
    }
  };

  const handleCloseSnackbar = () => {
    setCopied(false);
  };

  return (
    <>
      <TextField
        id="copy-link-input"
        name="copy-link-input"
        value={url}
        fullWidth
        variant="outlined"
        InputLabelProps={{
          shrink: true,
        }}
        InputProps={{
          readOnly: true,
          inputProps: {
            style: { flex: "1 1 0" },
          },
          startAdornment: (
            <InputAdornment position="start" sx={{ height: "auto" }}>
              <LinkIcon color="primary" />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end" sx={{ height: "auto" }}>
              <CopyIcon color="primary" />
              <Link
                component="button"
                underline="none"
                sx={{ whiteSpace: "nowrap", ml: "8px" }}
                onClick={handleCopyClick}
              >
                {t("copy")}
              </Link>
            </InputAdornment>
          ),
        }}
      />

      <Snackbar
        open={copied}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {t("copiedMessage")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default CopyLink;
