import React, { FC, useEffect, useMemo, useState } from "react";
import { useForm, UseFormSetError } from "react-hook-form";
import { isUndefined, noop } from "lodash";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { IOTPFormData } from "../../boundary/IOTPFormData";
import { getAuthService } from "../../diContainer/getDependencies";
import { Box, Button, Grid, Tooltip, Typography } from "@mui/material";
import { LayoutHeadless } from "./layouts/LayoutHeadless";
import { OtpInput } from "./form/OtpInput";
import LoadingButton from "@mui/lab/LoadingButton";

const DISPLAY_LAST_NUMBERS_QUANTITY = 4;
const RESEND_SMS_TIMEOUT = 30 * 1000;

interface Props {
  onSendOtpClick: (
    otp: string,
    setError: UseFormSetError<IOTPFormData>
  ) => void;
  onResendActivationCode?: () => void;
  phoneNumber: string;
  isLoading: boolean;
}

export const ValidateOtp: FC<Props> = ({
  onSendOtpClick,
  phoneNumber,
  onResendActivationCode,
  isLoading,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "signIn.validateOtp",
  });

  const authService = getAuthService();

  const navigate = useNavigate();
  const phoneNumberSlice = useMemo(() => {
    const phoneLength = phoneNumber.length;
    if (phoneLength < DISPLAY_LAST_NUMBERS_QUANTITY) return phoneNumber;
    const startIdx = phoneLength - DISPLAY_LAST_NUMBERS_QUANTITY;
    return phoneNumber.slice(startIdx, phoneLength);
  }, [phoneNumber]);
  const [resendInProgress, setResendInProgress] = useState(false);

  const hasCodeResend = !isUndefined(onResendActivationCode);
  const resendActivationFn = hasCodeResend
    ? () => {
        setResendInProgress(true);
        onResendActivationCode && onResendActivationCode();
      }
    : noop;

  const handleChangePhoneNumber = () => {
    authService.removeTokens();
    navigate(-1);
  };

  useEffect(() => {
    if (resendInProgress) {
      const to = setTimeout(
        () => setResendInProgress(false),
        RESEND_SMS_TIMEOUT
      );

      return () => {
        clearTimeout(to);
      };
    }
  }, [resendInProgress]);

  const {
    control,
    handleSubmit,
    formState: { isValid },
    setError,
  } = useForm<IOTPFormData>({
    mode: "onChange",
  });

  const onSubmit = (data: IOTPFormData) => {
    onSendOtpClick(data.otp, setError);
  };

  return (
    <LayoutHeadless>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h1" variant="h4" align="center">
            {t("header")}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            {t("text", { phoneNumberSlice })}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={handleChangePhoneNumber} color="primary">
              {t("changePhoneNumber")}
            </Button>

            {hasCodeResend &&
              (resendInProgress ? (
                <Typography variant="subtitle1">{t("waitResend")}</Typography>
              ) : (
                <Tooltip title={t("noCode")}>
                  <Button variant="outlined" onClick={resendActivationFn}>
                    {t("resendCode")}
                  </Button>
                </Tooltip>
              ))}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <OtpInput
              // @ts-ignore
              control={control}
            />
          </form>
        </Grid>
        <Grid item xs={12} alignContent="space-around">
          <LoadingButton
            onClick={handleSubmit(onSubmit)}
            disabled={!isValid}
            variant="contained"
            fullWidth
            loading={isLoading}
          >
            {t("buttonLabel")}
          </LoadingButton>
        </Grid>
      </Grid>
    </LayoutHeadless>
  );
};
