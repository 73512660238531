import { filter } from "rxjs";
import { MessageBus, StateSubject } from "@roketus/web-toolkit";
import type { IAuthMessageData, ISDK } from "@roketus/loyalty-end-user-js-sdk";
import type {
  IAuthService,
  IAuthServiceData,
} from "../../boundary/IAuthService";
import type { IProfileService } from "../../boundary/IProfileService";
import { getSdk } from "../../diContainer/getDependencies";
import {
  CustomCodeError,
  EC_AUTH_NO_AUTHORIZED,
  EC_AUTH_NO_COMPLETED,
} from "../../domain/specs/errorCodes";
import { pushErrorToFaro, pushLogToFaro } from "../../utils/faroLogs";
import {
  getNextRegStep,
  isRegistrationCompleted,
} from "../../utils/registration";
import { buildAnalyticMessageEntity } from "../../domain/entities/analyticEntity";

const stateMachine = new StateSubject<IAuthServiceData>({
  isAuthenticated: false,
  isAuthorized: false,
  isLoaded: false,
});

const setIsAuthenticated = () => {
  stateMachine.setState({
    isAuthenticated: true,
    isAuthorized: true,
    isLoaded: true,
  });
};

const setIsNotAuthenticated = () => {
  stateMachine.setState({
    isAuthenticated: false,
    isAuthorized: false,
    isLoaded: true,
  });
};

const setTokens: IAuthService["setTokens"] = (token, refreshToken) => {
  const sdk = getSdk();
  sdk.setToken(token, refreshToken);
};

const removeTokens: IAuthService["removeTokens"] = () => {
  const sdk = getSdk();
  sdk.removeToken();
  setIsNotAuthenticated();
};

const invokeIsAuth = async (
  sdk: ISDK,
  profileService: IProfileService,
  checkTokensWithoutReq: boolean = false
) => {
  try {
    const token = sdk.getToken();

    if (!token) {
      pushLogToFaro("No token in storage");

      setIsNotAuthenticated();
      throw new CustomCodeError(EC_AUTH_NO_AUTHORIZED);
    }

    pushLogToFaro("Use token from storage");

    if (checkTokensWithoutReq) return;

    const { isLoaded, isAuthenticated, isAuthorized } =
      stateMachine.state$.value;

    if (isLoaded) {
      if (isAuthenticated && isAuthorized) {
        return;
      } else {
        throw new CustomCodeError(EC_AUTH_NO_AUTHORIZED);
      }
    }

    const result = await profileService.getProfileInfo();

    if (!isRegistrationCompleted(result)) {
      const stepToComplete = getNextRegStep(result);

      pushErrorToFaro("Error: registration is not completed", {
        errorCode: EC_AUTH_NO_COMPLETED,
        nextStep: stepToComplete,
      });

      setIsNotAuthenticated();
      throw new CustomCodeError(EC_AUTH_NO_COMPLETED, {
        nextStep: stepToComplete,
      });
    }

    setIsAuthenticated();
  } catch (e) {
    throw e;
  }
};

export const init = (
  sdk: ISDK,
  messageBus: MessageBus,
  profileService: IProfileService
): IAuthService => {
  messageBus.stream$
    .pipe(filter(sdk.messages.checkIfAuthMessage))
    .subscribe((messageData) => {
      const analyticMeta = {
        tokenExpDate: (messageData.data as IAuthMessageData).tokenExpDate,
        refreshTokenExpDate: (messageData.data as IAuthMessageData).refreshTokenExpDate,
      };

      if ((messageData.data as IAuthMessageData).isAuthenticated) {
        setIsAuthenticated();

        const analyticEvent = buildAnalyticMessageEntity({
          action: "TokenRefreshed",
          source: "AuthService",
          meta: analyticMeta,
        });
        messageBus.send(analyticEvent);
        
        pushLogToFaro('Token Refreshed', analyticMeta);
      } else {
        setIsNotAuthenticated();

        const analyticEvent = buildAnalyticMessageEntity({
          action: "TokenExpired",
          source: "AuthService",
          meta: analyticMeta,
        });
        messageBus.send(analyticEvent);

        pushLogToFaro('Tokens Expired', analyticMeta);
      }
    });

  return {
    data$: stateMachine.state$,
    setTokens,
    removeTokens,
    setIsAuthenticated,
    setIsNotAuthenticated,
    invokeIsAuth: (checkTokensWithoutReq) => invokeIsAuth(sdk, profileService, checkTokensWithoutReq),
  };
};
