import { useParams } from "react-router-dom";
import { IActionService } from "../../boundary/IActionService";
import { getContainer } from "../../diContainer/container";
import { IssuerToken } from "../../domain/specs/issuerToken";
import { SurveyRetailate } from "../components/retailate/SurveyRetailate";
import { NotFound } from "../components/NotFound";
import { DemoatomicSurveyAction } from "../components/actionComponents/demoatomic/DemoatomicSurveyAction";
import { ISurveyFormPayload } from "../../boundary/forms/IFreeSurveyFormData";

export const SurveyAnonymousForm: React.FC = () => {
  const { issuerSysName } = useParams();

  const actionService = getContainer().getDependency(
    "actionService"
  ) as IActionService;

  const handleSubmit = (formValue: ISurveyFormPayload) => {
    actionService.submitFreeSurveyForm(formValue, issuerSysName);
  };

  if (issuerSysName?.toLowerCase() === IssuerToken.Retailete) {
    return <SurveyRetailate submitForm={handleSubmit} />;
  }

  if (issuerSysName?.toLowerCase() === IssuerToken.Demoatomic) {
    return <DemoatomicSurveyAction submitForm={handleSubmit} />;
  }

  return <NotFound />;
};
