import { IActionService } from "../../boundary/IActionService";
import { IIssuerConfigService } from "../../boundary/IIssuerConfigService";
import { getContainer } from "../../diContainer/container";
import { IssuerToken } from "../../domain/specs/issuerToken";
import { SurveyForm } from "../components/SurveyForm";
import { DemoatomicSurveyAction } from "../components/actionComponents/demoatomic/DemoatomicSurveyAction";

export const SurveyActionForm: React.FC = () => {
  const issuerConfigService = getContainer().getDependency(
    "issuerConfigService"
  ) as IIssuerConfigService;
  const actionService = getContainer().getDependency(
    "actionService"
  ) as IActionService;

  const issuerSysName = issuerConfigService.getIssuer();

  if (issuerSysName === IssuerToken.Demoatomic) {
    return <DemoatomicSurveyAction submitForm={actionService.submitSurveyForm} />
  }

  return <SurveyForm submitForm={actionService.submitSurveyForm} />;
};
