import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { IProfileService } from "../../../boundary/IProfileService";
import { IIssuerConfigService } from "../../../boundary/IIssuerConfigService";
import {
  CustomCodeError,
  EC_INVALID_ISSUER_SYS_NAME,
} from "../../../domain/specs/errorCodes";
import {
  isNoActivationCompletedError,
  isNoAuthorizedError,
  isNoCardError,
} from "../../../utils/response-errors";
import { IAuthService } from "../../../boundary/IAuthService";
import { getNextRegStepPath, isInvalidIssuerSysName } from "../../../utils/registration";

export const createProfileLoader =
  (
    issuerConfigService: IIssuerConfigService,
    profileService: IProfileService,
    authService: IAuthService,
    isDemo?: boolean
  ) =>
  async (props: LoaderFunctionArgs) => {
    const issuerSysName =
      props.params.issuerSysName || issuerConfigService.getIssuer();

    try {
      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      // NOTE: in case of demo jwtSignin, no need to check if profile activation was completed
      if (!isDemo) {
        await authService.invokeIsAuth();
      }

      if (!profileService.data$.value.profile) {
        await profileService.getProfileInfo();
      }

      return null;
    } catch (e: any) {
      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      if (isNoAuthorizedError(e)) {
        return redirect(`/${issuerSysName}/signin`);
      }

      if (isNoActivationCompletedError(e)) {
        const stepPath = getNextRegStepPath(e.extraInfo?.nextStep, true);
        return redirect(`/${issuerSysName}${stepPath}`);
      }

      if (isNoCardError(e)) {
        authService.removeTokens();
        return redirect(`/${issuerSysName}/signup`);
      }

      throw e;
    }
  };
