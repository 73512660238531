import React, { FC } from "react";
import { Outlet, useNavigation } from "react-router-dom";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "../ThemeProvider";
import LoaderPage from "../pages/LoaderPage";

export const App: FC = () => {
  const navigation = useNavigation();

  return (
    <ThemeProvider>
      <CssBaseline />
      {navigation.state === "loading" ? <LoaderPage /> : <Outlet />}
    </ThemeProvider>
  );
};
