import { FC, useEffect } from "react";
import { isEmpty } from "lodash";
import { Trans, useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import { Box, Grid } from "@mui/material";
import { useIsAuthenticated } from "../../../../adapters/hooks/useIsAuthenticated";
import { getContainer } from "../../../../diContainer/container";
import { isValidPhoneNumber } from "../../../../adapters/helpers";
import { IActionService } from "../../../../boundary/IActionService";
import { ISignupService } from "../../../../boundary/ISignUpService";
import qrIcon from "../../../icons/qr.svg";
import { DemoatomicLayout } from "./DemoatomicLayout";
import { PhoneInput } from "../../form/PhoneInput";
import welcomeImage from "./images/welcome-bg.svg";
import ActionButtonGroup from "./ActionButtonGroup";
import styles from "./styles.module.scss";

type TPhoneFormData = {
  phone: string;
};

interface IProps {
  isAnonymous: boolean;
  onSubmit: (formData: TPhoneFormData) => void;
  onCancel: () => void;
}

export const DemoatomicWelcomePage: FC<IProps> = ({
  isAnonymous,
  onSubmit,
  onCancel,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "action.demoatomic",
  });
  const actionService = getContainer().getDependency(
    "actionService"
  ) as IActionService;
  const signUpService = getContainer().getDependency(
    "signupService"
  ) as ISignupService;

  const actionData = useObservable(actionService.data$);

  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
    watch,
  } = useForm<TPhoneFormData>();

  const signUpData = useObservable(signUpService.data$);

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    if (!isEmpty(signUpData?.errorMessage)) {
      setError("phone", { message: signUpData?.errorMessage });
    }
  }, [setError, signUpData?.errorMessage]);

  const phoneNumberValue = watch("phone");
  const isDisabled =
    !isAnonymous &&
    (!isValidPhoneNumber(phoneNumberValue) || !isEmpty(errors)) &&
    !isAuthenticated;

  return (
    <DemoatomicLayout
      icon={qrIcon}
      title="bonusTitle"
      image={welcomeImage}
      className="welcomePage"
    >
      <>
        <p>
          <Trans
            i18nKey={"action.demoatomic.bonusText"}
            components={{ b: <b /> }}
          />
        </p>

        <Box
          component="form"
          noValidate
          onSubmit={handleSubmit(onSubmit)}
          sx={{ mt: 3, display: "block", width: "100%" }}
        >
          <Grid container spacing={2}>
            {!isAnonymous && !isAuthenticated && (
              <Grid item xs={12}>
                <label className={styles.phoneLabel}>{t("phoneLabel")}</label>
                <PhoneInput
                  className={styles.phoneInput}
                  control={control}
                  label={""}
                  fullWidth
                />
              </Grid>
            )}

            <ActionButtonGroup
              disabled={isDisabled || actionData?.loading}
              loading={actionData?.loading}
              handleConfirm={handleSubmit(onSubmit)}
              handleCancel={onCancel}
            />
          </Grid>
        </Box>
      </>
    </DemoatomicLayout>
  );
};
