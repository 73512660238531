import { LoaderFunctionArgs, redirect } from "react-router-dom";
import type { IIssuerConfigService } from "../../../boundary/IIssuerConfigService";
import {
  CustomCodeError,
  EC_INVALID_ISSUER_SYS_NAME,
} from "../../../domain/specs/errorCodes";
import { isInvalidIssuerSysName } from "../../../utils/registration";

export const createIssuerLoader =
  (issuerConfigService: IIssuerConfigService, isIssuerPath = true) =>
  async (props: LoaderFunctionArgs) => {
    let issuerSysName =
      props.params.issuerSysName || issuerConfigService.getIssuer();

    try {
      if (isInvalidIssuerSysName(issuerSysName))
        throw new CustomCodeError(EC_INVALID_ISSUER_SYS_NAME);

      if (!isIssuerPath) return redirect(`/${issuerSysName}`);

      const lowerCaseToken = issuerSysName!.toLocaleLowerCase();

      if (
        issuerConfigService.data$.value.loading ||
        issuerConfigService.data$.value.isLoaded
      ) {
        issuerConfigService.setIssuer(lowerCaseToken!);
        return null;
      }

      await issuerConfigService.fetchConfig(lowerCaseToken);
      issuerConfigService.setIssuer(lowerCaseToken);

      return null;
    } catch (e: any) {
      throw e;
    }
  };
