declare global {
  interface Window {
    ReactNativeWebView?: any;
  }
}

export const isIOS = (): boolean => {
  return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const isAndroidOS = (): boolean => /Android/.test(navigator.userAgent);

export const isIOSMobile = (): boolean =>
  /iPad|iPhone|iPod/.test(navigator.userAgent);

export const isWindowsMobile = (): boolean =>
  /Windows Phone/.test(navigator.userAgent);

export const isMobileDevice = (): boolean =>
  isAndroidOS() || isIOSMobile() || isWindowsMobile();

export const isSafariBrowser = (): boolean =>
  /Safari/.test(navigator.userAgent) && !/Chrome/.test(navigator.userAgent);

export const isSamsungBrowser = (): boolean =>
  /SamsungBrowser/.test(navigator.userAgent);

export const isWebView = () => {
  const userAgent = navigator.userAgent;
  const isAndroidWebView = /wv/.test(userAgent);
  const isIOSWebView = /iPhone|iPod|iPad/.test(userAgent) && !/Safari/.test(userAgent);
  const isHybrid = !!window.ReactNativeWebView;

  return isAndroidWebView || isIOSWebView || isHybrid || (window.history.length === 1);
}

export const isDarkThemeInBrowser = (): boolean =>
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

export const copyText = (textToCopy: string, onCopied: () => void) => {
  if (navigator.clipboard && navigator.clipboard.writeText) {
    navigator.clipboard
      .writeText(textToCopy)
      .then(() => {
        onCopied();
      })
      .catch((e) => {});
  } else {
    // for mobile devices and some old browsers
    const textArea = document.createElement("textarea");
    textArea.value = textToCopy;
    textArea.style.position = "fixed";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      const successful = document.execCommand("copy");
      successful && onCopied();
    } catch (err) {}

    document.body.removeChild(textArea);
  }
};
