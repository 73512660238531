import { FC } from "react";
import { Trans } from "react-i18next";
import { getContainer } from "../../../../diContainer/container";
import { IRouterService } from "../../../../boundary/IRouterService";
import styles from "./styles.module.scss";
import { Arrow } from "../../../icons/Arrow";

export const DemoatomicHomeButton: FC = ({}) => {
  const routerService = getContainer().getDependency("routerService") as IRouterService;

  const handleClose = () => {
    routerService.navigateToIssuerPath('/');
  };

  return (
    <button className={styles.buttonHome} onClick={handleClose}>
      <Trans i18nKey={`action.demoatomic.homeButton`}></Trans>
      <Arrow />
    </button>
  );
};
