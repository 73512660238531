import { Typography } from "@mui/material";
import { LayoutHeadless } from "../components/layouts/LayoutHeadless";
import { Trans, useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { IssuerToken } from "../../domain/specs/issuerToken";
import { NegativeRetailateResult } from "../components/retailate/NegativeRetailateResult";
import { DemoatomicActionCancel } from "../components/actionComponents/demoatomic/DemoatomicActionCancel";

export const NegativeResultPage: React.FC = () => {
  const { t } = useTranslation("translation", {
    keyPrefix: "infoPage",
  });

  const { issuerSysName } = useParams();

  if (issuerSysName?.toLowerCase() === IssuerToken.Retailete) {
    return <NegativeRetailateResult />;
  }

  if (issuerSysName?.toLowerCase() === IssuerToken.Demoatomic) {
    return <DemoatomicActionCancel />;
  }

  return (
    <LayoutHeadless>
      <Typography gutterBottom align="center">
        <Trans i18nKey={`infoPage.message`} components={{ br: <br /> }} />
      </Typography>
    </LayoutHeadless>
  );
};
