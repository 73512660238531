import { FC, useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import styled from "@emotion/styled";
import { Paper, Skeleton, Typography } from "@mui/material";
import { getRouterService } from "../../../diContainer/getDependencies";
import CopyLink from "./CopyLink";

interface IProps {
  loading?: boolean;
  refCode?: string | null;
}

const Container = styled(Paper)(({ theme }) => ({
  backgroundColor:
    (theme as any)?.palette?.mode === "light" ? "#F5F5F5" : "inherit",
  width: "100%",
  padding: "1.5rem 1rem",
  textAlign: "center",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
}));

const ReferralProgramBox: FC<IProps> = ({ loading, refCode }) => {
  const { t } = useTranslation("translation", { keyPrefix: "referralProgram" });

  const routerService = getRouterService();

  const referralLink = useMemo(
    () => routerService.getReferralLink(),
    [routerService]
  );

  if (loading) {
    return (
      <Skeleton animation="wave" variant="rounded" width="100%" height={192} />
    );
  }

  if (!refCode) {
    return null;
  }

  return (
    <Container>
      <Typography variant="h5" color="primary" fontWeight="bold">
        <Trans i18nKey={"title"} t={t} />
      </Typography>
      <Typography variant="body1">
        <Trans i18nKey={"description"} t={t} />
      </Typography>

      <CopyLink url={referralLink} />
    </Container>
  );
};

export default ReferralProgramBox;
