import { FC } from "react";
import { isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { useObservable } from "@roketus/web-toolkit";
import { Box, FormControl, Grid } from "@mui/material";
import { getContainer } from "../../../../diContainer/container";
import { IActionService } from "../../../../boundary/IActionService";
import { IRouterService } from "../../../../boundary/IRouterService";
import { ISurveyFormPayload } from "../../../../boundary/forms/IFreeSurveyFormData";
import chatIcon from "../../../icons/chat.svg";
import { DemoatomicLayout } from "./DemoatomicLayout";
import surveyImage from "./images/survey-bg.svg";
import ActionButtonGroup from "./ActionButtonGroup";
import { ControlRadioGroup } from "../../form/ControlRadioGroup";
import styles from "./styles.module.scss";

interface ISurveyFormData {
  survey: string;
}

interface IProps {
  submitForm: (data: ISurveyFormPayload) => void;
}

export const DemoatomicSurveyAction: FC<IProps> = ({
  submitForm,
}) => {
  const { t } = useTranslation("translation", {
    keyPrefix: "action.demoatomic",
  });
	
  const actionService = getContainer().getDependency(
    "actionService"
  ) as IActionService;
  const routerService = getContainer().getDependency(
    "routerService"
  ) as IRouterService;

  const actionData = useObservable(actionService.data$);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<ISurveyFormData>({
    defaultValues: { survey: "" },
  });

  const radioItems = [
    {
      label: t("surveyYes"),
      value: "true",
    },
    {
      label: t("surveyNo"),
      value: "false",
    },
  ];

  const handleConfirm = ({ survey }: ISurveyFormData) => {
    submitForm({
      surveyDescription: "Authorized user survey",
      surveyData: [
        {
          questionDescription: t("surveyQuestion"),
          questionAnswers: [
            {
              name: "yes",
              value: survey === "true",
            },
            {
              name: "no",
              value: survey !== "true",
            },
          ],
        },
      ],
    });
  };

  const handleCancel = () => {
    routerService.navigateToIssuerPath("/info");
	};

  return (
    <DemoatomicLayout
      icon={chatIcon}
      title="surveyTitle"
      image={surveyImage}
      className="surveyPage"
    >
      <Box
        component="form"
        onSubmit={handleSubmit(handleConfirm)}
        display="flex"
        flexDirection="column"
        alignItems="center"
      >
        <Grid container gap={2}>
					<Grid item xs={12}>
						<FormControl className={styles.radioButtons}>
							<ControlRadioGroup<ISurveyFormData>
								control={control}
								radioItems={radioItems}
								name="survey"
								required
								checkedClassName={styles.checked}
							/>
						</FormControl>
					</Grid>

          <ActionButtonGroup
            disabled={!isEmpty(errors) || actionData?.loading}
            loading={actionData?.loading}
            handleConfirm={handleSubmit(handleConfirm)}
            handleCancel={handleCancel}
          />
        </Grid>
      </Box>
    </DemoatomicLayout>
  );
};
